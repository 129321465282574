.loginMobInput {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.loginMobInput::-webkit-inner-spin-button,
.loginMobInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
