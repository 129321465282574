@font-face {
  font-family: "Font700";
  src: url("/src/assets/fonts/Montserrat/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "Font600";
  src: url("/src/assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Font500";
  src: url("/src/assets/fonts/Montserrat/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "Font400";
  src: url("/src/assets/fonts/Montserrat/Montserrat-Regular.ttf");
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.search_input {
  width: 30rem;
  z-index: 100;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @media (max-width: 1920px) and (min-width: 1501px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1590px !important;
  }
}
@media (max-width: 1500px) and (min-width: 1440px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1400px !important;
  }
}
@media (max-width: 1439px) and (min-width: 1366px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1340px !important;
  }
}
@media (max-width: 1365px) and (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1240px;
  }
} */
:root {
  --primary-color: #075CF6;
  --primary-red: #EA4335;
  --secondary-color: #4b506d;
  --heading-color: #000000;
  --subheading-color: #121212;
  --dark-heading: #202124;
  --light-heading: #5f6368;
  --text-color: #333333;
  --form-text: #8f9291;
  --light-text-color: #808080;
  --white-color: #ffffff;
  --black-color: #000000;
  --gray-light :#F5F5F5;
  --gray-800: #6d7071;
  --gray-1000: #4a4f51;
  --social: #2cae97 !important;
  --academic: #567eac !important;
  --collaboration: #294f82 !important;
  --communication: #f29639 !important;
  --global: #e36141 !important;
  --innovation: #cd3e53 !important;
  --digital: #ae2c66 !important;
  --other: #5c3c75 !important;
  --rating : #1f990e !important;
  --individual-color: #034ea3;
  --mentoring-color: #5c3c75;
  --course-color: #567eac;
  --primary-font-regular: "Font400";
  --primary-font-medium: "Font500";
  --primary-font-semibold: "Font600";
  --primary-font-bold:"Font700"
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .navbar-toggler-icon {
    background-image: var(--mdb-navbar-toggler-icon-bg) !important;
  }
}