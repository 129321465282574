@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.suggestions-dropdown {
  position: absolute; /* Position it below the input */
  z-index: 1000; /* Ensure it appears above other elements */
  background-color: white; /* Background color for dropdown */
  border: 1px solid #ccc; /* Border for dropdown */
  border-radius: 4px; /* Rounded corners */
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);  */
  max-height: 200px; /* Limit height */
  overflow-y: auto; /* Enable scrolling */
  width: 99%; /* Full width under input */
}

.suggestions-dropdown div {
  padding: 10px; /* Spacing for items */
  cursor: pointer; /* Hand cursor on hover */
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  color: #333;
}

.suggestions-dropdown div:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}

a {
  cursor: pointer;
  text-decoration: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-link {
  font-family: "Montserrat", sans-serif;
  font-weight: 600 !important;
}

.custom-navbar {
  justify-content: space-between; /* Align items horizontally */
  padding-bottom: 0px !important; /* Add top padding */
}

.logo {
  width: 200px; /* Adjust logo width */
}
.footer-logo {
  width: 210px;
}
.icon {
  width: 22px;
  height: 22px;
}

.search-form {
  flex-grow: 1;
  text-align: center;
  display: flex;
  margin: 0 37px;
  justify-content: center;
}
.search-form button.btn.btn-outline-primary {
  border-radius: 0 24px 24px 0;
  background-color: #075cf6;
  color: #fff;
  padding-right: 15px;
}
.search-form input {
  font-family: "Montserrat", sans-serif;
  font-weight: 400 !important;
  border-width: 0;
  font-size: 12px;
  width: 512px;
  background: #f5f5f5;
  color: #919191;
  border-radius: 20px 0 0 20px;
}
section.wrapper_near .container {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 50px;
  border-top: 1px solid #d9d9d9;
  margin-top: 50px;
}
section.wrapper_topDeals .container {
  border-top: 1px solid #d9d9d9;
  padding-top: 40px;
}
section.wrapper_topDeals {
  padding-bottom: 50px;
  padding-top: 50px;
}
.custom-navbar .container {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 8px;
}

.nav-link {
  margin-left: 0px; /* Add margin between nav links */
}

.wrapper_storeRegistration .form-label,
.wrapper_setStoreForm .form-label,
.wrapper_addProduct .form-label,
.wrapper_createOffer .form-label,
.billingInfo .form-label,
.form-wrap input,
.form-wrap textarea {
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
  color: #333;
}
.form-wrap div {
  margin-bottom: 20px;
}
.wrapper_storeRegistration form > div,
.wrapper_setStoreForm form > div,
.wrapper_addProduct form > div,
.wrapper_createOffer form > div,
.billingInfo > div {
  margin-bottom: 20px;
}
.wrapper_storeRegistration .form-control,
.wrapper_setStoreForm .form-control,
.wrapper_addProduct .form-control,
.wrapper_createOffer .form-control,
.billingInfo .form-control,
.basicInfo .form-control {
  font-size: 18px;
  background: #f5f5f5;
  border-width: 0px;
  border-radius: 6px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
  color: #333;
}
.wrapper_storeRegistration .form-control:focus,
.wrapper_setStoreForm .form-control:focus,
.wrapper_addProduct .form-control:focus,
.wrapper_createOffer .form-control:focus,
.billingInfo .form-control:focus {
  color: var(--text-color);
  background-color: var(--white-color);
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(13, 110, 253, 0.25);
}
@media (min-width: 1601px) {
  .custom-navbar .container,
  .custom-navbar .container-lg,
  .custom-navbar .container-md,
  .custom-navbar .container-sm,
  .custom-navbar .container-xl {
    max-width: 1800px !important;
    width: 100% !important;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1600px !important;
    width: 100% !important;
  }
}
@media (min-width: 1367px) and (max-width: 1600px) {
  .custom-navbar .container,
  .custom-navbar .container-lg,
  .custom-navbar .container-md,
  .custom-navbar .container-sm,
  .custom-navbar .container-xl {
    max-width: 1440px !important;
    width: 100% !important;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1300px !important;
    width: 100% !important;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  .custom-navbar .container,
  .custom-navbar .container-lg,
  .custom-navbar .container-md,
  .custom-navbar .container-sm,
  .custom-navbar .container-xl {
    max-width: 1366px !important;
    width: 100% !important;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px !important;
    width: 100% !important;
  }
}
.wrapper_storeRegistration .progress {
  width: 900px;
  height: 8px;
  margin: 0 auto;
  background-color: #e6e6e6;
}
.wrapper_storeRegistration .progress .progress-bar {
  font-size: 0;
  background-color: #a2c3ff;
}
.wrapper_mainCategory .main-category-col:hover,
.wrapper_mainCategory .selected-category {
  background-color: #fff !important;
  box-shadow: 10px 10px 25px 0px #00000014;
  border: 1px solid var(--primary-color);
  /* transition: box-shadow 0.3s ease-in-out; */
}

.wrapper_mainCategory .main-category-col:hover > h2,
.wrapper_mainCategory .selected-category > h2 {
  color: var(--primary-color) !important;
  /* transition: box-shadow 0.3s ease-in-out; */
}
.wrapper_mainCategory .main-category-col:hover {
  cursor: pointer;
}
.wrapper_mainCategory .main-category-col,
.wrapper_near .singleShop {
  /* transition: box-shadow 0.3s ease-in-out; */
}
.wrapper_mainCategory .main-category-col,
.wrapper_near .singleShop {
  /* animation: fadeIn 1s; */
}
.wrapper_near .singleShop:hover {
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px, rgb(0 0 0 / 25%) 0px 4px 4px;
}
.wrapper_near .react-multi-carousel-item {
  padding: 10px 0px;
}
.location-modal .btnBlue {
  background-color: var(--primary-color);
  border-radius: 16px;
  padding: 10px 20px;
  color: var(--white-color);
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
}
.btnBlue {
  background-color: var(--primary-color) !important;
  border-radius: 16px !important;
  padding: 10px 20px !important;
  color: var(--white-color) !important;
  font-size: 16px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
  border-color: transparent !important;
}
.btnDelete {
  background-color: var(--primary-red) !important;
  border-radius: 16px !important;
  padding: 10px 20px !important;
  color: var(--white-color) !important;
  font-size: 16px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
  border-color: transparent !important;
}
.type-btn button {
  font-size: 16px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
}
.submit.btnBlue {
  width: 200px;
}
.btnCancel {
  background-color: var(--text-color) !important;
  border-radius: 16px !important;
  padding: 10px 20px !important;
  color: var(--white-color) !important;
  font-size: 16px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
  border-color: transparent !important;
}
body.modal-open {
  position: relative;
}
.location-modal.modal.show .modal-dialog {
  transform: none;
  position: absolute;
  left: 11%;
  top: 10%;
}
.language-modal.modal.show .modal-dialog {
  transform: none;
  width: 126px;
  position: absolute;
  right: 13%;
  top: 0%;
}
.location-modal .btnWhite {
  background-color: var(--white-color);
  border-radius: 16px;
  padding: 10px 20px;
  color: #808080;
  font-size: 16px;
  border: 1px solid #d9d9d9dd;
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
}
.language-modal .modal-title.h4 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.language-modal .modal-content {
  border-radius: 16px;
}
.language-modal .modal-content .form-check {
  margin: 10px 0;
}
.store .wrapper_mainCategory {
  display: none;
}
.language-modal .modal-content .form-check-label,
.paymentRadio .form-check-label,
.product-delivery .form-check-label {
  color: #333;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
}
.logoutModal .modal-title,
.cancelOrderModal .modal-title,
.deleteAddressModal .modal-title,
.ratingModal .modal-title,
.sellerModal .modal-title {
  color: #000;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600 !important;
}
.store-rating-wrap {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  background: rgb(255 255 255 / 48%);
  padding: 10px;
}
.logoutModal .modal-body,
.cancelOrderModal .modal-body,
.deleteAddressModal.modal-body,
.ratingModal .modal-body,
.sellerModal .modal-body {
  color: #333;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
}
.store section.wrapper_subcateoryWiseProduct {
  background: #fff;
  border-radius: 30px 30px 0 0;
  margin-top: -70px;
  padding-top: 30px;
}
section.wrapper_userProfile .container.below {
  background: #fff;
  padding-top: 20px;
  margin-left: 0;
  padding-bottom: 20px;
  border-radius: 16px 16px 0 0;
  margin: 0 auto;
  width: 100% !important;
  margin-top: 30px;
}
.wrapper_userProfile,
.wrapper_viewCart {
  background: #f0f6ff;
  padding-top: 20px;
}
.language-modal .modal-content .form-check-input {
  --bs-form-check-bg: #d9d9d9 !important;
}
.language-modal .modal-content .form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: none !important;
}

.wrapper_subcateoryWiseProduct .subcat li {
  position: relative !important;
}
.wrapper_subcateoryWiseProduct .subcat li:hover {
  cursor: pointer;
}
.wrapper_subcateoryWiseProduct .subcat li.selected::after {
  content: "";
  position: absolute;
  top: 0;
  width: 8px;
  bottom: 0;
  background-color: var(--primary-color);
  right: 0;
  border-radius: 10px 0px 0px 10px;
  height: 50px;
}
.supplyMethodModal .btn-close {
  position: absolute;
  left: 50%;
  right: 50%;
  top: -10px;
  border: 0.5px solid #c6c6c6;
  font-size: 15px;
  color: #333;
  border-radius: 100px;
  padding: 19px;
  font-weight: 700;
  background: white var(--bs-btn-close-bg) center / 1em auto no-repeat;
  opacity: 1;
}
.supplyMethodModal .modal-title.h4 {
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 600;
  line-height: 48px;
  color: #333 !important;
}
.supplyMethodModal .form-check,
.wrapper_createOffer .form-check {
  margin-bottom: 20px;
}
.supplyMethodModal .form-check-input,
.wrapper_createOffer .form-check-input {
  margin-top: 8px;
}
.supplyMethodModal .form-check-label,
.wrapper_createOffer .form-check-label {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  line-height: 30.48px;
  text-align: left;
}
.user-profile-tab a.nav-link,
.user-profile-tab .store-links {
  font-family: "Montserrat";
  cursor: pointer;
  font-weight: 500 !important;
  font-size: 18px;
  color: #333 !important;
  padding: 10px !important;
  background-image: url("./assets/images/arrow-right.png");
  background-position: center right;
  background-size: 32px;
  margin-right: 20px !important;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.user-profile-tab .nav-link span:first-child,
.user-profile-tab .store-links span:first-child {
  background-color: #f5f5f5;
  color: #b0b0b0;
  /* font-size: 48px; */
  border-radius: 100px;
  margin-right: 10px;
  padding: 10px;
  width: 50px;
  height: 50px;
  text-align: center;
}
.user-profile-tab .profile-icon {
  /* background-color: #f5f5f5; */
  color: #b0b0b0;
  font-size: 24px;
  /* border-radius: 100px; */
  /* margin-right: 10px; */
  /* padding: 10px; */
}
.user-profile-tab.nav-pills .nav-link.active,
.user-profile-tab.nav-pills .show > .nav-link {
  color: var(--primary-color) !important;
  background-color: transparent;
}
.user-profile-tab.nav-pills .nav-link.active .profile-icon,
.user-profile-tab.nav-pills .show > .nav-link .profile-icon {
  color: var(--primary-color);
  background-color: #f0f6ff;
}

/* .user-profile-tab.nav.nav-pills {
  border-right: 1px solid #d9d9d9 !important;
} */
.wrapper_userProfile .col-sm-9 {
  border-left: 1px solid #d9d9d9 !important;
  padding-left: 15px;
}
.tab-title {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 30px;
}
.address-wrap .form-check-label {
  display: block !important;
}
.productTable td {
  vertical-align: middle;
  padding-top: 20px;
  padding-bottom: 20px;
}

.pointer {
  cursor: pointer;
}
.wrapper_view-order .productTable td,
.wrapper_view-order .productTable th {
  border-bottom-width: 0px !important;
  padding-top:3px !important;
  padding-bottom:3px !important;
}
.wrapper_addProduct .form-check-label {
  font-family: "Montserrat";
  font-size: 25px;
  font-weight: 500;
  text-align: left;
}
.wrapper_addProduct .form-check-input {
  margin-right: 10px;
  width: 32px;
  height: 32px;
}
.wrapper_addProduct .form-check-inline {
  margin-right: 50px;
  margin-left: 50px;
}
.wrapper_productDetails .breadcrumb-item {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #333333;
}
.wrapper_productDetails .breadcrumb-item a,
.wrapper_productDetails .breadcrumb-item {
  text-decoration: none;
  color: #333 !important;
}
.wrapper_productDetails .breadcrumb-item a {
  color: var(--primary-color) !important;
  display: inline-block;
}
.wrapper_productDetails .breadcrumb-item.active {
  color: #808080 !important;
}
.logoutModal .modal-content,
.deleteAddressModal .modal-content {
  background-image: url(./assets/images/title-bg.png) !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  align-items: center !important;
  position: relative;
}
.cancelOrderModal .modal-content,
.ratingModal .modal-content,
.sellerModal .modal-content {
  background-image: url(./assets/images/title-bg.png) !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  /* align-items: flex-start !important; */
  position: relative;
}
.logoutModal .modal-content .btn-close,
.deleteAddressModal .modal-content .btn-close,
.cancelOrderModal .modal-content .btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
  --bs-btn-close-color: #000 !important;
}
.cancelOrderModal .modal-body {
  width: 100%;
}
.switchModal .modal-body {
  background-image: url(./assets/images/title-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  background-size: cover;
  background-position: center;
  min-height: 130px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  font-family: "Montserrat";
  font-size: 28px;
  font-weight: 600;
}
.switchModal .modal-body img {
  padding-right: 20px;
  margin-right: 20px;
  border-right: 2px solid var(--primary-color);
}

/* Cart Counter */
.add-to-cart {
  display: flex;
  align-items: center;
  /* background-color: #f5f5f5; */
  border-radius: 16px;
}

.quantity {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 500;
  line-height: 29.26px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  margin: 0 10px;
}

.quantity-button {
  font-weight: 600;
  background-color: #c6c6c6;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 24px;
  font-family: "Montserrat";
  border-radius: 16px;
  line-height: 1.2;
  width: 40px;
  height: 36px;
}

.quantity-button.add {
  background-color: var(--primary-color);
}

.quantity-button:hover {
  background-color: var(--primary-color); /* Button color on hover */
}
@media (min-width: 1800px) and (max-width: 2000px) {
  .singleShop {
    padding: 15px !important;
    margin-right: 20px !important;
  }
  .wrapper_mainCategory .main-category-col {
    min-height: 300px !important;
  }
  .main-category-col > div {
    text-align: center !important;
    margin-top: 30px;
  }
  .product-image-wrap {
    height: 228px !important;
    overflow: hidden !important;
    padding: 10px !important;
  }
  img.product-image {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
  }
  .product-card {
    height: 430px !important;
  }
  .wrapper_subcateoryWiseProduct .product-card {
    height: 410px !important;
  }
  .wrapper_subcateoryWiseProduct .product-image-wrap {
    height: 170px !important;
    overflow: hidden !important;
    padding: 10px !important;
  }
  .product-row .react-multi-carousel-item {
    padding: 0 25px 0 0;
  }
  .product-card .product-name {
    width: 230px !important;
  }
  .wrapper_offers .product-card .product-name {
    width: 280px !important;
  }
}
@media (min-width: 1367px) and (max-width: 1600px) {
  .wrapper_near .shopName {
    width: 200px !important;
  }
  .wrapper_offers .product-name,
  .wrapper_subcateoryWiseProduct .product-name {
    font-size: 16px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  .wrapper_near .shopName {
    width: 200px !important;
  }
}
.product-image-wrap {
  height: 228px !important;
  overflow: hidden !important;
  padding: 10px !important;
}
img.product-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}
.product-card {
  height: 430px !important;
}
.wrapper_offers .product-card {
  height: 450px !important;
}
.wrapper_subcateoryWiseProduct .product-card {
  height: 410px !important;
}
.wrapper_subcateoryWiseProduct .product-image-wrap {
  height: 170px !important;
  overflow: hidden !important;
  padding: 10px !important;
}

.wrapper_searchTab .product-card {
  height: 450px !important;
}
.wrapper_searchTab .product-card .product-name {
  min-height: 40px;
}
.product-card .product-name {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 180px;
  min-height: 2.4em !important;
}
.wrapper_offers .product-card .product-name {
  width: 200px;
}
.global-products .product-card .product-name {
  /* min-height: 50px; */
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 240px;
  min-height: 2.4em !important;
}
.cartProductInfoCol h3 {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 180px;
  min-height: 2.4em !important;
}
.cartProductInfoCol {
  padding-left: 30px !important;
}
.breadcrumb-item a {
  color: var(--primary-color);
}
/* .product-name  */
.global-products .product-card {
  height: 460px !important;
}
.productReviews.global-products .product-card {
  height: 370px !important;
}
.react-simple-star-rating {
  margin-bottom: 0px !important;
}
.product-row .react-multi-carousel-item {
  padding: 0 25px 0 0;
}
.wrapper_searchTab .nav-tabs .nav-link {
  font-family: "Montserrat";
  font-weight: 500 !important;
  font-size: 16px !important;
  color: var(--text-color);
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.wrapper_searchTab .nav-tabs {
  border-bottom: 0px !important;
}
.wrapper_searchTab .nav-tabs .nav-link:hover,
.wrapper_searchTab .nav-tabs .nav-link:focus {
  border-color: transparent !important;
}
.wrapper_searchTab .nav-tabs .nav-link {
  margin: 0 30px 0 0 !important;
}
.wrapper_searchTab .nav-tabs .nav-link.active {
  color: var(--primary-color);
  background-color: transparent;
  border-color: transparent !important;
  font-weight: 600 !important;
  border-bottom: 2px solid var(--primary-color) !important;
}
.wrapper_searchTab {
  margin-top: -40px;
}
.cart-row {
  position: relative;
}
.cart-col-right {
  position: sticky;
  right: 0;
  top: 30px;
}
.cart-items-wrap {
  overflow-y: auto;
  max-height: 400px; /* Maximum height of the container */
  /* height: 400px;  */
  padding-right: 10px;
}
.cart-items-wrap::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.cart-items-wrap::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.cart-items-wrap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}

/* Handle on hover */
.cart-items-wrap::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}

/* .cart-items-wrap {
  overflow-y: scroll;
  max-height: 400px;
  padding-right: 10px;
  scrollbar-width: 10px; 
} */

/* .cart-items-wrap::-webkit-scrollbar {
  display: none; 
} */

/* .cart-items-wrap:hover::-webkit-scrollbar{
  display: block; 
  width: 10px !important;
  background-color: #f5f5f5;
} */

.wrapper_wishlist::-webkit-scrollbar-track,
.success-order-item::-webkit-scrollbar-track,
.wrapper_userProfile .wrapper_orders::-webkit-scrollbar-track,
/* .cart-items-wrap::-webkit-scrollbar-track, */
.suggestions-dropdown::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.wrapper_wishlist::-webkit-scrollbar,
.success-order-item::-webkit-scrollbar,
.wrapper_userProfile .wrapper_orders::-webkit-scrollbar ,
/* .cart-items-wrap::-webkit-scrollbar, */
.suggestions-dropdown::-webkit-scrollbar {
  width: 10px !important;
  background-color: #f5f5f5;
}

.wrapper_wishlist::-webkit-scrollbar-thumb,
.success-order-item::-webkit-scrollbar-thumb,
.wrapper_userProfile .wrapper_orders::-webkit-scrollbar-thumb,
/* .cart-items-wrap::-webkit-scrollbar-thumb, */
.suggestions-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}
.mini-cart-dropdown .no-product-card {
  box-shadow: none !important;
}
.cart-items-wrap .cart-summary a.btnBlue.btn.btn-primary {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  margin: 0 auto;
}
.wrapper_userProfile .wrapper_orders {
  width: 100%;
  padding-right: 10px;
}
.wrapper_wishlist,
.wrapper_userProfile .wrapper_orders {
  width: 100%;
  float: left;
  height: 600px;
  min-height: 600px;
  overflow-y: auto;
  margin-bottom: 25px;
  overflow-x: hidden;
}
.success-order-item {
  float: left;
  height: 300px;
  min-height: 300px;
  overflow-y: auto;
  margin-bottom: 25px;
  overflow-x: hidden;
}

.storeRatingModal .modal-content {
  padding-right: 5px;
}
#product-details-tabs-tabpane-reviews::-webkit-scrollbar-track,
.storeRatingModal .modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#product-details-tabs-tabpane-reviews::-webkit-scrollbar,
.storeRatingModal .modal-body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#product-details-tabs-tabpane-reviews::-webkit-scrollbar-thumb,
.storeRatingModal .modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}
#product-details-tabs-tabpane-reviews {
  width: 100%;
  padding-right: 10px;
}
#product-details-tabs-tabpane-reviews,
.storeRatingModal .modal-body {
  width: 100%;
  float: left;
  max-height: 400px;
  margin-top: 5px;
  overflow-y: auto;
  margin-bottom: 25px;
  overflow-x: hidden;
}
.product-card .productContent > p,
.cartImageCol > p,
.minmax {
  font-size: 11px !important;
  font-family: "Montserrat" !important;
  font-weight: 600;
  margin-top: 5px !important;
  text-align: center;
  margin-left: 0px !important;
}
.minmax {
  margin-left: 10px !important;
}
.cartImageCol {
  text-align: center;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
#product-details-tabs-tabpane-reviews .react-simple-star-rating {
  pointer-events: none;
}

.priceWrap {
  margin-bottom: 5px !important;
}

.Toastify__toast-body {
  font-size: 14px;
  font-family: "Montserrat";
  color: #333 !important;
  font-weight: 500;
}
svg.svg-inline--fa.fa-heart {
  color: #e93f31 !important;
}
.addressWrap .form-check-input[type="radio"] {
  display: none !important;
}
.addressWrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.addressWrap > .col-sm-4 {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-inline: 1%;
}
.addressWrap .form-check {
  padding-left: 0px !important;
}
.about-us .wrapper_titlebar .image img {
  bottom: -70px !important;
  width: 380px !important;
}
.contact-us .wrapper_titlebar .image img {
  bottom: -70px !important;
  width: 350px !important;
}
.terms-conditions .wrapper_titlebar .image img {
  bottom: -70px !important;
  width: 400px !important;
}
.faqs .wrapper_titlebar .image img {
  bottom: -70px !important;
  width: 200px !important;
}
.contact-info.row > div.col-sm-4{
  border: 1px solid #b0b0b0;
  border-radius: 16px;
  width: 32%;
  margin-right: 0.5%;
  margin-left: 0.5%;
  padding: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.form-wrap {
  width: 600px;
  margin: 20px auto 0;
  background: #f0f6ff;
  padding: 15px;
  border-radius: 6px;
}

.contact-info.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.contact-info.row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}
.form-wrap input,
.form-wrap textarea {
  border-width: 0px;
}

.wrapper_faqs li.nav-item button {
  border-radius: 24px;
  width: 100%;
  border: 1px solid #b0b0b0 !important;
  font-family: "Montserrat";
  font-size: 18px;
  color: #333 !important;
  font-weight: 500 !important;
}
.wrapper_faqs li.nav-item {
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}
.wrapper_faqs .nav-tabs {
  border-bottom-width: 0 !important;
  padding-bottom: 30px;
}
.wrapper_faqs .accordion-button {
  background-color: #f0f6ff !important;
  font-family: "Montserrat";
  font-size: 20px;
  color: #333 !important;
  font-weight: 600;
  text-align: left;
}
.wrapper_faqs .accordion-collapse.collapse.show {
  background: #fafbff;
  border-radius: 0px 0px 16px 16px;
}
.wrapper_faqs .accordion-item {
  margin-bottom: 20px;
  border-radius: 16px !important;
  border-width: 0 !important;
}
.wrapper_faqs .accordion-body {
  font-family: "Montserrat";
  font-size: 16px;
  color: #333 !important;
  font-weight: 500;
  text-align: left;
}
.wrapper_faqs
  .accordion-item:first-of-type
  > .accordion-header
  .accordion-button,
.wrapper_faqs
  .accordion-item:last-of-type
  > .accordion-header
  .accordion-button.collapsed {
  border-radius: 16px;
}
.wrapper_faqs .accordion-button:focus {
  box-shadow: none !important;
}
.wrapper_faqs
  .accordion-item:first-of-type
  > .accordion-header
  .accordion-button:not(.collapsed) {
  border-radius: 16px 16px 0px 0px;
}
.wrapper_faqs #faq-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper_faqs .nav-tabs .nav-item.show .nav-link,
.wrapper_faqs .nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.wrapper_footer li a {
  color: #fff !important;
}
.inner-wrap {
  margin-top: 30px;
  margin-bottom: 20px;
}
li.page-item {
  font-size: 14px !important;
  font-family: "Montserrat";
  font-weight: 500;
}
li.page-item .page-link {
  color: var(--primary-color);
}
li.page-item.active .page-link {
  color: var(--white-color);
}
.viewProduct .wrapper_titlebar .image img {
  width: 285px !important;
}
.inner-wrap.row {
  margin-top: 30px;
}
.store-dates {
  font-family: "Montserrat";
  font-weight: 600;
}
.store-dates input[type="date"],
.store-dates .form-select {
  padding: 5px 10px;
  border-radius: 10px;
  border-style: solid;
  border-color: #333 !important;
  margin: 0 10px;
  font-weight: 600;
  border-width: 2px !important;
}
.search-input,
.search-input .wrapper input {
  font-family: "Montserrat";
  font-weight: 500 !important;
  font-size: 16px !important;
  border-width: 0 !important;
  padding-left: 20px;
}
.search-btn {
  background-color: #fff !important;
  color: #333 !important;
  border-width: 0px !important;
}
ul.sortPopover {
  margin-bottom: 0px !important;
}
ul.sortPopover li button {
  font-family: "Montserrat";
  font-weight: 500;
  color: #333;
  font-size: 14px;
  text-decoration: none;
}
.addProductWrap .wrapper_titlebar .image img {
  width: 300px;
}
.multiSelectContainer {
  font-family: "Montserrat";
  font-weight: 500;
}
.editProduct .wrapper_titlebar .image img {
  max-width: 300px;
}
.global20 {
  margin-right: 20px;
}
.sortImage {
  width: 50px;
}

@media only screen and (min-device-width: 760px) and (max-device-width: 1180px) {
  #search-tab-tabpane-Products .col-sm-3 {
    padding: 0px 5px !important;
  }
  .language-modal.modal.show .modal-dialog {
    left: 20%;
    right: auto;
  }
  .show-sm,
  .order-wrap .show-sm {
    display: block !important;
  }
  .wrapper_mainCategory .main-category-col h2 {
    font-size: 18px !important;
    margin-bottom: 10px !important;
  }
  .search-form {
    margin: 0 15px;
  }
  .sm-product-image-row .col-sm-3 {
    width: 50% !important;
  }
  .sm-product-image-row .col-sm-9 {
    width: 50% !important;
  }
  .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%2833, 37, 41, 0.75%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
  }
  .sm-edit-image-row.row .col-sm-11 {
    width: 80%;
  }
  .search-form input {
    width: 250px;
  }
  .custom-navbar.desktop {
    display: none !important;
  }
  .custom-navbar.mobile.tablet {
    display: block !important;
  }
  .sm-edit-image-row.row .col-sm-1 {
    width: 20%;
  }
  .btnRowWrap .btn-primary {
    min-width: 140px !important;
  }
  .order-wrap .infoCol.col-sm-4 {
    width: 40%;
  }
  .order-wrap .imageCol.col-sm-2 {
    width: 30%;
  }
  .order-wrap .priceCol.col-sm-2 {
    width: 30%;
  }
  .addressWrap > .col-sm-4 {
    width: 48%;
  }
  .wrapper_storeRegistration .progress {
    width: 100% !important;
  }
  .wrapper_storeRegistration .mb-5.container > h2 {
    font-size: 20px !important;
  }
  .wrapper_mainCategory .main-category-col {
    padding: 15px 10px !important;
    min-height: 180px !important;
  }
  .wrapper_mainCategory .main-cat-img-wrap {
    height: 125px !important;
  }
  .wrapper_near .shopName {
    width: 170px !important;
  }
  .wrapper_near .singleShop {
    margin-right: 10px !important;
  }
  .f-col4 p,
  .f-col1 p,
  .f-col1 .btn-primary {
    width: 100% !important;
  }
  .row-subcateoryWiseProduct .col-sm-9 .col-sm-3 {
    width: 33.33% !important;
  }
  .row-subcateoryWiseProduct .product-card .product-name {
    width: 150px !important;
    font-size: 16px !important;
  }
  .wrapper_near .supply-wrap {
    bottom: 0;
    border-bottom-right-radius: 20px !important;
  }
  .wrapper_storeView {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    margin-bottom: 0px !important;
  }
  .wrapper_nearByShop,
  .wrapper_nearByProducts {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .wrapper_nearByProducts .col-sm-3 {
    width: 33.33% !important;
  }
  .store section.wrapper_subcateoryWiseProduct {
    margin-top: -30px !important;
    padding-top: 10px !important;
  }
  .sm-whiteWrap {
    padding: 20px 15px !important;
  }
  .cart-row.row .col-sm-7 {
    width: 70% !important;
    padding-right: 15px !important;
  }
  .cart-row .cartImageCol div {
    width: 80px !important;
    height: 80px !important;
  }
  .hide-sm {
    display: none !important;
  }
  .global-products .product-card .product-name {
    width: 170px !important;
  }
  #search-tab-tabpane-Products .global-products .product-card .product-name {
    width: 150px !important;
  }
  .sm-login .col-md-6 {
    width: 100% !important;
  }
  .cart-row.row .col-sm-5 {
    width: 30% !important;
    padding-left: 15px !important;
    padding-top: 20px !important;
    padding-bottom: 0px;
  }
  .sm-checkout {
    min-width: 200px !important;
  }
  .btn-outOfStock {
    font-size: 14px !important;
  }
  .wrapper_nearByShop .shopName {
    width: 120px !important;
  }
  .user-profile-tab .nav-link span,
  .user-profile-tab .store-links span {
    background-color: #f5f5f5;
    color: #b0b0b0;
    /* font-size: 48px; */
    border-radius: 100px;
    margin-bottom: 5px;
    margin-right: 0px;
    padding: 10px;
    width: 50px;
    height: 50px;
    text-align: center;
  }
  .user-profile-tab a.nav-link,
  .user-profile-tab .store-links {
    text-align: center;
    font-size: 14px;
    margin-right: 10px !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .wrapper_nearByShop .supply-method {
    width: 120px !important;
  }
  .wrapper_nearByShop .supply-method p {
    font-size: 11px !important;
  }
  .wrapper_nearByShop .shopInfo {
    margin-left: 0px !important;
  }
  .wrapper_nearByShop .sm-shopImageWrap {
    width: 100px !important;
    height: 100px !important;
  }
}
@media (max-width: 768px) {
  .navbar-toggler-icon {
    background-image: url("./assets/images/menu.png") !important;
  }
}
@media (max-width: 767px) {
  #product-details-tabs-tabpane-reviews {
    padding: 0 20px !important;
  }
  .addressWrap > .col-sm-4 {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    margin-inline: 0%;
}
  .wishlistSort {
    margin-top: -30px !important;
  }
  .global-products {
    margin-top: 20px;
  }
  .xs-wrap {
    float: none !important;
    overflow-x: scroll;
    width: 230px;
    margin: 0 auto;
    display: block !important;
  }
  .store-xs-wrap {
    float: none !important;
    overflow-x: scroll;
    width: 320px;
    margin: 0 auto;
    display: block !important;
  }
  .viewProductImageSlider {
    padding-right: 0px !important;
    margin: 10px auto !important;
  }
  .btnRowWrap {
    width: 100%;
    padding: 0px !important;
    margin: 0px !important;
  }
  .track-order {
    margin-top: 5px !important;
  }
  .btnRowWrap > .col {
    padding-left: 0px !important;
    justify-content: center !important;
    padding-right: 0px !important;
    display: flex;
    flex-direction: column;
  }
  .tracker-body {
    margin-top: 0px !important;
    padding: 0px !important;
  }
  .wrapper_tracking .progress {
    bottom: 50px !important;
  }
  .tracking-steps.icon-wrap li div .icon {
    width: 14px;
    height: 15px;
  }
  .tracking-steps.icon-wrap li div {
    width: 40px !important;
    height: 40px !important;
  }
  .tracking-steps .label {
    font-size: 11px !important;
    text-align: center;
  }
  .wrapper_userProfile .order-wrap .infoCol,
  .wrapper_userProfile .order-wrap .priceCol,
  .wrapper_userProfile .order-wrap .statusCol {
    text-align: center !important;
  }
  .location-modal .btnWhite {
    width: 100%;
  }
  .switchModal .modal-body {
    font-size: 15px !important;
  }
  .success-order-item {
    padding: 20px 40px 0px 0px !important;
  }
  .submit.btnBlue {
    width: 150px;
    font-size: 14px !important;
  }
  .wrapper_userProfile .below .row .col-sm-3{
    width: 25%;
    float: left;
  }
  .wrapper_userProfile .below .col-sm-9{
    width: 75%;
    float: left;
  }
  .user-profile-tab a.nav-link,  .user-profile-tab a.store-link{
    display: block !important;
    text-align: center;
    font-size: 10px !important;
    background-image: none !important
  }
  .wrapper_orders .order-wrap {
    width: 48%;
    float: left;
    margin: 0 1%;
}
  .user-profile-tab a.nav-link span{
    margin-bottom: 10px;
    background-color: #f5f5f5;
    color: #b0b0b0;
    border-radius: 100px;
    margin-right: 10px;
    padding: 10px;
    width: 50px;
    line-height: 25px;
    height: 50px;
    text-align: center;
    margin-bottom: 5px;;
    display: block;
  }
  .user-profile-tab .nav-link span:first-child, .user-profile-tab .store-links span:first-child {
    background-color: #f5f5f5;
    color: #b0b0b0;
    /* font-size: 48px; */
    border-radius: 100px;
    margin-right: 0px;
    padding: 10px;
    width: 50px;
    height: 50px;
    text-align: center;
}
  .success-order-summary {
    margin-top: 30px;
  }
  .wrapper_addProduct {
    margin-top: 0px !important;
  }
  .wrapper_addProduct .form-check-inline {
    margin-right: 10px;
    margin-left: 10px;
  }
  .wrapper_addProduct .form-check-input {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
  .wrapper_addProduct .form-check-label {
    font-size: 14px !important;
  }
  .wrapper_storeRegistration .form-label,
  .wrapper_setStoreForm .form-label,
  .wrapper_addProduct .form-label,
  .wrapper_createOffer .form-label,
  .billingInfo .form-label,
  .form-wrap input,
  .form-wrap textarea {
    font-size: 14px !important;
  }
  .wrapper_storeRegistration .form-control,
  .wrapper_setStoreForm .form-control,
  .wrapper_addProduct .form-control,
  .wrapper_createOffer .form-control,
  .billingInfo .form-control {
    font-size: 14px !important;
  }
  .wrapper_storeRegistration form > div,
  .wrapper_setStoreForm form > div,
  .wrapper_addProduct form > div,
  .wrapper_createOffer form > div,
  .billingInfo > div {
    margin-bottom: 10px;
  }
  .wrapper_nearByShop .flex-row {
    position: relative !important;
  }
  .wrapper_nearByShop .shopRow {
    margin-top: 40px;
  }

  .store-dates {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 11px;
  }
  .store-dates input[type="date"] {
    padding: 5px 7px;
    border-radius: 10px;
    border-style: solid;
    border-color: #333 !important;
    margin: 0 5px;
    font-weight: 600;
  }
  .custom-navbar .navbar-toggler-icon {
    background-image: var(--bs-navbar-toggler-icon-bg) !important ;
  }
  .f-col2.col-sm-3,
  .f-col3.col-sm-3 {
    width: 50%;
  }
  .search-form input {
    width: 100% !important;
  }
  .search-form {
    margin: 10px 0 !important;
    width: 100%;
  }
  .logo {
    width: 160px !important;
  }
  .wrapper_mainCategory.mt-5 {
    margin-top: 20px !important;
  }
  .language-modal.modal.show .modal-dialog {
    right: 20% !important;
  }
  .location-modal.modal.show .modal-dialog {
    left: 0;
    top: 20%;
  }
  .location-modal.modal .modal-body h2 {
    font-size: 20px !important;
  }
  .location-modal.modal .modal-body .or {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .location-modal.modal .modal-body button {
    font-size: 14px !important;
    padding: 10px !important;
    width: 100%;
  }
  .location-modal .or {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .wrapper_offers .react-multi-carousel-list {
    padding: 0 5px !important;
  }
  .location-modal .btnWhite {
    font-size: 14px;
    text-align: center;
  }
  .flex-column-sm {
    flex-direction: column;
  }
  .titlebar-title {
    margin-bottom: 0px !important;
    font-size: 20px !important;
  }
  .cat-row.row .col {
    width: 100% !important;
    max-width: 100% !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .mobile-category {
    font-size: 11px !important;
    font-family: "Montserrat";
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
  }
  .main-category-col {
    position: relative;
    min-height: 70px !important;
    height: 70px !important;
    padding: 2px !important;
  }
 
  .main-category-col > div {
    height: 80px !important;
    position: absolute;
    top: 0px;
  }
  .main-category-col > div img {
    object-fit: contain;
  }
  section.wrapper_near .container {
    padding-bottom: 20px;
    margin-top: 20px;
    padding-top: 20px;
  }
  .inner-wrap a {
    font-size: 12px !important;
  }
  .noData {
    margin-top: 20px !important;
  }
  .noData h3 img {
    width: 20px !important;
    height: 20px !important;
  }
  .noData h3 {
    padding: 5px 10px !important;
    font-size: 12px !important;
    margin-bottom: 0px !important;
  }
  .wrapper_near.mb-5 {
    margin-bottom: 0px !important;
  }
  .wrapper_offers.mb-5 {
    margin-bottom: 20px !important;
  }
  .wrapper_subcateoryWiseProduct {
    background: #f5f5f5;
    border-radius: 30px 30px 0 0;
    padding-top: 2px;
    margin: 10px 5px;
    box-shadow: 2px 2px 20px 0px #00000014;
  }
  .wrapper_subcateoryWiseProduct ul.subcat .blue-border {
    width: 2px !important;
  }
  .offer-badge {
    width: 31px !important;
    height: 41px !important;
  }
  .offer-badge > div {
    border-left: 15px solid transparent !important;
    border-right: 15px solid transparent !important;
    border-bottom: 10px solid rgb(245, 245, 245) !important;
  }
  .offer-badge > p,
  .product-card .price {
    font-size: 11px !important;
  }

  .product-image-wrap {
    height: 100px !important;
  }
  .product-card .product-name,
  .productContent > h3,
  .product-card .offerPrice {
    font-size: 11px !important;
  }
  .product-card .product-name {
    width: 110px !important;
  }
  .shopInfo {
    margin-bottom: 0 !important;
  }
  .priceWrap {
    margin-bottom: 5px !important;
  }
  .wrapper_offers .product-card .product-name {
    width: 100px !important;
  }
  .wrapper_offers .product-card {
    height: 300px !important;
  }
  section.wrapper_nearByProducts .row .col-sm-3:last-child {
    width: 50% !important;
    max-width: 50% !important;
  }
  section.wrapper_nearByProducts {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .global-products .product-card .product-name {
    font-size: 13px !important;
    margin: 5px 0px !important;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 113px;
    min-height: 2.4em !important;
  }
  .global20 {
    margin-right: 10px !important;
  }
  .shopImageWrap {
    width: 30px !important;
    height: 30px !important;
    padding: 0px !important;
  }
  .shopName {
    font-size: 10px !important;
  }
  .global-products .col-sm-3.col {
    width: 50%;
    max-width: 50%;
    padding: 0px 5px;
    float: left;
  }
  .global-products .product-card > div.productContent {
    padding: 5px 10px 10px !important;
  }
  .global-products .product-card {
    height: 315px !important;
  }
  .product-card .btn-primary {
    font-size: 12px !important;
    padding: 5px 10px !important;
  }
  .product-card {
    height: 290px !important;
  }
  .product-card .wt-brand {
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-top: 5px !important;
  }
  .product-card .wt-brand > div {
    height: 20px !important;
  }
  .product-card .wt-brand h3 {
    margin-top: 5px !important;
    margin-bottom: 0px !important;
  }
  .wrapper_subcateoryWiseProduct .product-card {
    height: 295px !important;
  }
  .shopRow.row .col-sm-4 > .row {
    width: 100%;
    margin: 0px !important;
    padding: 0px !important;
  }
  .wrapper_subcateoryWiseProduct .product-card {
    background-color: #fff !important;
  }
  .wrapper_subcateoryWiseProduct .product-image-wrap {
    height: 100px !important;
    background-color: #fff !important;
    border-bottom: 2px solid #f5f5f5;
  }
  .productContent {
    padding: 5px !important;
  }
  .product-row .react-multi-carousel-item {
    padding-right: 5px !important;
  }
  .wrapper_near .singleShop {
    margin-right: 5px !important;
    flex-direction: column;
    padding: 5px 0px 0px !important;
  }
  .wrapper_near .singleShop .shopImgWrap {
    width: 80px !important;
    height: 80px !important;
  }
  .wrapper_near .singleShop .shopName {
    font-size: 11px !important;
    width: 100px !important;
    margin-top: 7px;
    margin-bottom: 7px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .wrapper_near .shopInfo {
    width: 100%;
    padding: 0px !important;
  }
  .wrapper_near .shopInfo .loc-wrap {
    flex-direction: column;
    margin-bottom: 5px !important;
  }
  .wrapper_near .shopInfo .loc-wrap h3 {
    font-size: 11px !important;
    margin-bottom: 5px !important;
  }
  .wrapper_near .shopInfo .loc-wrap h3 img {
    width: 10px !important;
    height: 10px !important;
  }
  .wrapper_near .shopInfo .loc-wrap p {
    margin-left: 0px !important;
    font-size: 11px !important;
  }
  .wrapper_near .dist-supply {
    flex-direction: column;
    margin-bottom: 0px !important;
  }
  .wrapper_near .dist-supply h3,
  .supply-wrap p {
    font-size: 9px !important;
  }
  .d-flex.add-row {
    flex-direction: column !important;
  }
  .add-product-wrap.d-flex.justify-content-start {
    position: relative !important;
    justify-content: space-between !important;
    width: 97%;
    margin-top: 15px;
  }
  .productList .form-control {
    width: 100px !important;
    /* margin: 0 20px 0 15px; */
  }
  .productList th,
  .productList td {
    padding: 0px 10px !important;
  }
  .seller-card-row.mb-5 {
    margin-bottom: 20px !important;
  }
  .row-subcateoryWiseProduct.row > .col-sm-3 {
    width: 25%;
    float: left;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .row-subcateoryWiseProduct.row > .col-sm-9 {
    width: 75%;
    float: left;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .mobile-subcatName{
    text-align: center !important;
  }
  .row-subcateoryWiseProduct.row > .col-sm-9 .col-sm-3 {
    width: 50%;
    float: left;
    margin-bottom: 10px;
  }
  .seller-card-row .col-md-3 .card-title {
    font-size: 14px !important;
  }
  .account.wrapper_aboutUs {
    padding-top: 10px !important;
    padding-bottom: 50px !important;
  }
  .card-row.seller-card-row p.card-text {
    font-size: 13px;
    padding: 5px;
  }
  .store-dates.mb-store-dates {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-direction: row-reverse;
  }
  .selectedAddressWrap.py-4 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .cart-col-right {
    padding-left: 15px !important;
  }
  .innerWrap > div > p {
    margin-left: 20px;
    margin-bottom: 0px !important;
  }
  .thumbnail {
    width: 70px !important;
    height: 70px !important;
    margin: 0 10px;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 5px !important;
  }
  .wrapper_productDetails .breadcrumb-item {
    font-size: 12px !important;
  }
  .productDetailsRow.my-5 {
    margin-top: 0 !important;
    padding: 0px 15px !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .custom-navbar.mobile,
  .hide-lg,
  .mobile-category,
  .subcatImgWrap,
  .mobile-subcatName {
    display: none !important;
  }
  .location-modal.modal.show .modal-dialog {
    max-width: 530px !important;
  }
}

/* Hide the desktop navbar on screens up to 767px */
@media (max-width: 767px) {
  .custom-navbar.desktop,
  .hide-xs,
  .subcatName,
  .desktop-category {
    display: none !important;
  }
  .subcatImgWrap{
    width: 80px;
    height: 80px;
    overflow: hidden;
  }
  .subcatImgWrap img.subcatImg{
    width: 100%;
    height:100%;
  }
  .bogo-wrap{
    font-size: 6px !important;
  }
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.custom-arrow.left {
  left: 10px;
}

.custom-arrow.right {
  right: 10px;
}

.thumbnail-container .thumbnail img {
  border: 2px solid transparent;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
}
.thumbnail {
  width: 100px;
  height: 100px;
  margin: 0 10px;
}

.thumbnail-container .thumbnail img:hover,
.thumbnail-container .thumbnail img.active {
  border: 1px solid #808080;
}

.tracking-status {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.tracking-steps {
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0;
}

.tracking-steps li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tracker-body {
  margin-top: 20px;
  position: relative;
}
.wrapper_tracking .card {
  border-width: 0px !important;
}

.tracking-steps li span {
  margin-bottom: 0.5rem;
}
.wrapper_tracking .progress {
  height: 4px !important;
  position: absolute;
  bottom: 75px;
  left: 0;
  width: 95%;
  margin: 0 auto;
  right: 0;
}
.arrow-left {
  margin-right: 20px;
  font-weight: 800;
  font-size: 24px !important;
}
.tracking-header-row {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.trackProductImageWrap {
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.shipping-info h2 {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
  text-align: left;
}
.shipping-info h3 {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  text-align: left;
}
.shipping-info p {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 500;
  color: #333;
  text-align: left;
}
.trackProductImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.status-label {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-top: 30px;
  font-family: "Montserrat";
  margin-bottom: 0px !important;
}
.wrapper_tracking .progress-bar {
  font-size: 0px !important;
}
.tracking-steps .label {
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
  color: #333;
}
.tracking-steps .complete .label {
  font-weight: 600;
  color: var(--primary-color);
}
.tracking-steps .label {
  margin-top: 10px;
}
.tracking-steps .icon {
  font-size: 32px;
  color: #808080;
}
.tracking-steps.icon-wrap {
  position: relative;
  margin-bottom: 0px;
  z-index: 999;
}
.tracking-steps.icon-wrap li div {
  background-color: #f5f5f5;
  padding: 10px;
  width: 52px;
  height: 52px;
  border-radius: 100px;
  align-items: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
}
.tracking-steps.icon-wrap li.complete div {
  background-color: #f0f6ff;
}
.tracking-steps.icon-wrap li.complete .icon {
  color: var(--primary-color);
}
.btn-outOfStock.btn:disabled {
  color: var(--primary-red) !important;
  border-color: var(--primary-red) !important;
  background: #fff !important;
  border: 1px solid !important;
}
.search-input.form-control:focus {
  box-shadow: none !important;
}
.product-card .add-to-cart {
  margin: 0 auto;
}

.err {
  color: var(--primary-red);
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
}
.Active {
  color: green !important;
}
.Inactive {
  color: var(--primary-red) !important;
}
.chip,
.multiSelectContainer li:hover,
.highlightOption {
  background: var(--primary-color) !important;
}
.productList p {
  font-size: 15px !important;
  font-family: "Montserrat";
  font-weight: 500;
  margin-bottom: 0px;
}
.productList th {
  font-size: 16px !important;
  font-family: "Montserrat";
  font-weight: 600;
  margin-bottom: 0px;
}
.productList td {
  padding: 0 !important;
}
.productList p {
  font-size: 15px !important;
  font-family: "Montserrat";
  font-weight: 400;
  color: #333;
  padding: 0 !important;
  margin-bottom: 0px !important;
}
.productList .form-control {
  width: 200px;
}
.productList {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; */
}
.productList tr {
  border-bottom: 1px solid #dee2e6;
}
.productList .imgWrap {
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.productList .imgWrap .prdImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.fileDownloadIconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #333;
  font-size: 15px;
  font-family: "Montserrat";
  font-weight: 500;
}
.search-form input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}
.search-form input {
  font-family: "Montserrat" !important;
  font-weight: 500;
  padding-inline: 20px !important;
}

.fileDownloadIcon {
  width: 25px;
  height: 25px;
  margin-bottom: 5px;
  color: "#333";
  border-radius: 100px;
  background-color: #f5f5f5;
  padding: 10px;
}

.viewProduct .wrapper_addProduct input,
.viewProduct .wrapper_addProduct textarea {
  pointer-events: none !important;
}
.btnDownloadInvoice {
  color: #fff;
  font-size: 15px;
  font-family: "Montserrat";
  font-weight: 500;
  margin-top: 20px;
  border-radius: 16px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  padding: 10px 20px;
}

.no-product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 300px;
  margin: 30px auto;
}

.no-product-image {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
}

.no-product-card h2 {
  margin: 0;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  color: #333333;
  font-weight: 500;
}
.pac-container {
  font-family: "Montserrat" !important;
  padding: 10px;
  font-weight: 600 !important;
}
.order-search .input-group {
  border: 1px solid #ddd;
  border-radius: 10px;
  background: transparent !important;
}
.order-search .sortImage {
  width: 40px;
  height: 40px;
}
.order-search .search-input,
.order-search .search-btn {
  background: transparent !important;
}
@media (max-width: 767px) {
  .locationAutoComplete {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .no-product-card h2 {
    font-size: 14px !important;
  }
  .no-product-image {
    width: 80px !important;
    height: 80px !important;
  }
  .no-product-card {
    padding: 10px 10px !important;
    max-width: 200px !important;
    margin: 10px auto !important;
  }
}
#product-details-tabs.nav-tabs .nav-link {
  border-radius: 0 !important;
}
#product-details-tabs.nav-tabs .nav-item.show .nav-link,
#product-details-tabs.nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
#product-details-tabs-tabpane-reviews .reviews {
  margin-top: 40px;
}

#product-details-tabs-tabpane-reviews .review {
  border-bottom: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 10px;
}

#product-details-tabs-tabpane-reviews .review-header {
  display: flex;
  align-items: flex-start;
}

#product-details-tabs-tabpane-reviews .profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

#product-details-tabs-tabpane-reviews .name {
  font-weight: bold;
  font-family: "Montserrat" !important;
}

#product-details-tabs-tabpane-reviews .rating {
  color: #f39c12;
}

#product-details-tabs-tabpane-reviews .comment {
  margin-top: 10px;
  font-family: "Montserrat" !important;
  font-weight: 500;
  color: #333;
}
#notificationHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

#notificationBody {
  margin-top: 10px;
  text-align: center;
}

#imageContainer {
  display: flex;
  align-items: center;
  height: 100px;
  object-fit: contain;
}
.thankYou {
}
.jumbotron-md {
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 0.3rem;
  margin: 4rem 0;
  max-width: 700px !important;
}
.thankYouWrapper h1 {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 30px;
  color: #333 !important;
  margin-top: 10px;
  margin-bottom: 10px !important;
}
.thankYouWrapper p {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 20px;
  color: #333 !important;
  margin-top: 10px;
  margin-bottom: 20px !important;
}

.brandName {
  color: var(--white-color);
  min-height: 15px;
  font-size: 12px;
  font-family: var(--primary-font-semibold);
  padding: 2px 5px;
  border-radius: 5px;
  background-color: var(--primary-red);
}
.lineItem {
  font-size: 14px;
  font-family: "Montserrat";
}
.lineItem .key {
  font-weight: 600;
  opacity: 0.9;
  color: var(--primary-red);
}
.btn.custom-btn {
  font-size: 18px !important;
  font-family: var(--primary-font-semibold) !important;
  min-width: 200px;
  margin-top: 0px;
  text-align: center;
  width: 19%;
  margin-left: 0.5%;
  margin-right: 0.5%;
}
.common-page li {
  margin-bottom: 10px;
}
.common-page .row {
  margin-bottom: 20px;
}
.common-page h4 {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  color: #333;
}
.cart-icon-container {
  position: relative;
}

.mini-cart-dropdown {
  position: absolute;
  top: 40px; /* Adjust depending on your layout */
  right: 0;
  width: 350px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.cart-top h3 {
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: 600;
  color: #333;
}
.mini-cart-dropdown .cart-item .product-image-wrap {
  width: 75px !important;
  height: 75px !important;
  overflow: hidden !important;
  background: #f7f7f7;
  border-radius: 5px;
  padding: 5px !important;
  min-width: 75px !important;
}
.mini-cart-dropdown .cart-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;
  border-bottom: 1px solid #ddd;
}
.mini-cart-dropdown .cart-item .cart-item-details {
  padding-left: 20px;
}
.mini-cart-dropdown .cart-item .cart-item-details p {
  font-size: 13px;
  font-family: "Montserrat";
  font-weight: 500;
  color: #333;
  margin-bottom: 0px;
}

.product-card .badge.bg-danger {
  background-color: #ea4335 !important;
  border-radius: 100px;
  font-size: 10px;
  padding: 10px 6px;
  width: 40px;
  height: 40px;
  line-height: 10px;
  position: absolute;
  left: 0px;
  bottom: 0;
  font-family: "Montserrat";
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: #545454 !important;
  border-color: #545454 !important;
  color: var(--bs-btn-disabled-color);
  opacity: var(--bs-btn-disabled-opacity);
  pointer-events: none;
}

.search-input {
  width: 95% !important;
}
.search-input .wrapper .dYrfwz {
  min-height: 44px;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.search-input .wrapper .search-icon {
  display: none;
}
.search-btn {
  border-top-right-radius: 24px !important;
  width: 5% !important;
  border-bottom-right-radius: 24px !important;
  border: 1px solid #ddd !important ;
}
.search-input .wrapper {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  box-shadow: none !important;
  border-right: 0px !important;
}

.blink_me.bg-primary {
  background-color: var(--primary-red) !important;
  font-family: "Montserrat";
  font-size: 11px !important;
  /* animation: blinker 1s linear infinite; */
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.custom-navbar .search-form .wrapper {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.custom-navbar .search-form .wrapper:focus-within {
  box-shadow: none !important;
}
.product-card {
  position: relative;
}
.product-card .new-arrival {
  width: 40px;
  position: absolute;
  right: -10px;
  top: -3px;
  z-index: 9999;
}
.searchSuggestion img {
  margin-right: 20px !important;
}

.searchSuggestion span {
  font-family: "Montserrat" !important;
  color: #333 !important;
  font-weight: 500;
}
.order-input {
  padding: 10px;
  border-radius: 24px 0px 0px 24px;
  padding-left: 20px;
}
.order-search-btn.search-btn {
  padding: 10px;
  border-width: 0px !important;
  border-radius: 0px 24px 24px 0px;
  margin-top: -3px;
  margin-left: -3px;
}

.order-input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.account-main-table tr td:nth-child(1) {
  width: 70% !important;
  border-right: 1px solid #ddd;
}
.account-main-table tbody > tr > td:nth-child(1) {
  font-weight: 600;
}
.account-main-table tbody > tr > td:nth-child(1) table tbody tr td {
  font-weight: 500;
}

.account-main-table table tr td {
  padding: 5px !important;
  vertical-align: middle !important;
}
.account-main-table tr td:nth-child(2),
.account-main-table tr td:nth-child(3) {
  width: 15% !important;
  vertical-align: middle !important;
  border-right: 1px solid #ddd;
}
.account-main-table table tr td:nth-child(3) {
  border-right: 0px solid #ddd !important;
}
.account-main-table,
.card-row {
  font-family: "Montserrat";
  font-weight: 500;
}
.method {
  background: var(--primary-red);
  color: #fff !important;
  font-size: 15px;
  border-radius: 6px !important;
  font-family: "Montserrat";
  font-weight: 500;
  padding: 2px 10px;
}
.account-main-table button.btn.btn-link {
  background: var(--primary-color);
  color: #fff !important;
  font-size: 20px;
  border-radius: 0px !important;
  margin-right: 10px;
  text-decoration: none;
  width: 30px !important;
  padding: 0px;
}

.card-row p.card-text {
  font-size: 20px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  color: #fff;
  background-color: var(--primary-color);
  padding: 5px;
  margin: 10px 0px 10px 0px;
}
.card-row .card {
  /* padding:20px 15px !important; */
}

@media (max-width: 768px) {
  .search-btn {
    width: 15% !important;
  }
  .search-input {
    width: 85% !important;
  }
  .brandName {
    font-size: 11px !important;
  }
  .email-verify-wrap {
    width: 100% !important;
    flex-direction: column;
  }
  .basicInfo .form-control {
    margin-bottom: 10px !important;
  }
  .prod-reviews {
    margin-top: 30px !important;
  }
  .prod-reviews h1 {
    font-size: 18px !important;
  }
  .productReviews.global-products .product-card,
  .prod-reviews .product-card {
    height: 230px !important;
  }
  .productReviews.global-products .product-card div.mb-2 {
    min-height: 30px;
  }
  .productReviews.global-products .product-card div.mb-2 img {
    margin-right: 10px !important;
  }
  .mb-center {
    justify-content: center !important;
  }
  .mb-center h3 {
    text-align: center !important;
  }
  .mb-center.justify-content-start {
    justify-content: center !important;
  }
  .storeTime .react-datepicker-wrapper {
    width: 100% !important;
  }
  .storeTime input.form-control.mx-3 {
    margin: 0 0 !important;
  }
  .productTable .btnBlue {
    font-size: 12px !important;
    padding: 5px 10px !important;
  }
  .productTable td {
    padding: 10px 12px !important;
  }
  .btn.custom-btn {
    font-size: 12px !important;
    font-family: var(--primary-font-semibold) !important;
    min-width: auto;
    margin-top: 0px;
    text-align: center;
    width: 49%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    margin-bottom: 7px;
  }
  .mobile-float-none {
    float: none !important;
  }
  .d-flex.add-row {
    flex-direction: column !important;
  }
  .add-product-wrap.d-flex.justify-content-start {
    position: relative !important;
    justify-content: space-between !important;
    width: 97%;
    margin-top: 15px;
  }
  .productList .form-control {
    width: 100px !important;
    /* margin: 0 20px 0 15px; */
  }
  .productList th,
  .productList td {
    padding: 0px 10px !important;
  }
  .seller-card-row.mb-5 {
    margin-bottom: 20px !important;
  }
  .seller-card-row .col-md-3 {
    width: 50%;
    float: left;
    margin-bottom: 10px;
  }
  .seller-card-row .col-md-3 .card-title {
    font-size: 14px !important;
  }
  .account.wrapper_aboutUs {
    padding-top: 10px !important;
    padding-bottom: 50px !important;
  }
  .card-row.seller-card-row p.card-text {
    font-size: 13px;
    padding: 5px;
  }
  .store-dates.mb-store-dates {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .mb-store-dates button.btn.btn-primary {
    padding: 3px 15px !important;
  }
  .account-main-table tr td:nth-child(2),
  .account-main-table tr td:nth-child(3) {
    width: 25% !important;
  }
  .account-main-table tr td:nth-child(1) {
    width: 50% !important;
  }
}
.date-card .nav-tabs .nav-item.show .nav-link,
.date-card .nav-tabs .nav-link.active {
  background-color: var(--primary-color);
  color: #fff !important;
  border-radius: 5px 5px 0 0 !important;
  border-bottom: 1px solid  var(--primary-color);
}
.date-card .nav-tabs .nav-link {
  border-radius: 0 !important;
  border-bottom: 1px solid #d2d2d2;
}
.date-card .nav-tabs {
  margin-bottom: 0px !important;
  border-bottom-width: 0 !important;
}
.date-card .nav-tabs .nav-item{
  margin-bottom: 0px !important;
}

.f-social a {
  font-size: 12px;
  color: #fff;
}
.f-social a:hover {
  opacity: 0.7;
}
.bogo-wrap {
    padding: 3px 10px;
    background: var(--primary-red);
    color: #fff;
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 600;
    margin-left: -1px;
    margin-right: -1px;
}
.freeIcon{
  position: absolute;
  left: -15px;
  top: -15px;
}
.freeIcon img{
  width: 50px;
  height: 50px;
  transform: rotate(-40deg);
}
.free-product .cartImageCol{
  position: relative;
}
.offer-status.btn.btn-primary{
  color:var(--white-color);
  background-color: var(--primary-red) !important;
  border-radius: 0px;
  font-size: 12px !important;
  font-family: var(--primary-font-semibold) !important;
  opacity: 1 !important;
  border-color: var(--primary-red) !important;
}
.account-pdf-download.row {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
}

.account-pdf-download.row button{
  background: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
  padding: 10px 15px;
  box-shadow: none;
  font-size: 20px;
  border-radius: 6px;
}

.grayed-out {
     -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.submit.btnBlue:disabled{
  background-color: #808080 !important;
  /* cursor: no-drop; */
}
.track-cancel:disabled{
  color:#fff !important;
}
.order-search .search-btn{
  border:0 !important;
}
.order-search .search-input{width:93% !important}
.order-search .search-btn{width:7% !important}
.quantity-button.add:disabled {
  background-color: gray;
}
.notification-container {
  position: relative;
  cursor: pointer;
}

.notification-dropdown {
  position: absolute;
  right: -10px;
  top: 1.5rem;
  width: 23rem;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  /* padding:rem; */
  z-index: 1000;
}
.notification-heading{
    background-color: var(--primary-color);
    color: #ffff;
    text-align: center;
    padding: 0.8rem;
    font-weight: bold;
    border-radius: 10px 10px 0 0;
}

.notification-dropdown h5 {
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.notification-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notification-dropdown li {
  display: flex;
  align-items: center;
  padding: 0.3rem 0;
  border-bottom: 1px solid #f0f0f0;
}

.notification-dropdown li:last-child {
  border-bottom: none;
}

.notification-dropdown .green-dot {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: green;
  margin-right: 0.5rem;
}

.notification-dropdown .message {
  flex-grow: 1;
  margin: 0;
}

.notification-dropdown .timestamp {
  font-size: 0.8rem;
  color: #999;
}
.notification-item p{
  font-family: "Montserrat";
  font-weight: 500;
  color:#333;
  font-size: 12px;
}

.notification-item .ago{
  font-family: "Montserrat";
  font-weight: 400;
  color:#999;
  font-size: 11px;
}

.cat-row  .react-multi-carousel-item {
    /* width: 297px !important; */
    margin: 0 auto;
    padding: 0px 12px;
}

@media (max-width: 767px) {
  .cat-row  .react-multi-carousel-item {
  /* width: 104px !important; */
  margin: 0 auto;
  padding: 0px 5px;
}
}